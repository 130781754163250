<template>
    <section>
        <b-card no-body >
            <b-card-header>
                <b-card-title>{{ $t('Merchant') }}</b-card-title>
            </b-card-header>
            <b-card-body>
                <div style="display: flex;justify-content: space-between;">
                    <vxe-button status="primary" @click="insertEvent()">{{ $t('Add') }}</vxe-button>
                    <vxe-form :data="formData" @submit="findList" v-if="isEdit">
                        <vxe-form-item title="" field="name" :item-render="{}">
                            <template #default="{ data }">
                            <vxe-input v-model="data.name" :placeholder="$t('Merchant name')"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item>
                            <template #default>
                            <vxe-button type="submit" status="primary">{{ $t('Search') }}</vxe-button>
                            <vxe-button type="reset">{{ $t('Reset') }}</vxe-button>
                            </template>
                        </vxe-form-item>
                    </vxe-form>
                </div>
                <vxe-table
                border
                show-overflow
                keep-source
                ref="xTable"
                max-height="400"
                :data="items"
                :edit-rules="validRules"
                @edit-closed="editClosedEvent"
                :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
                empty-text="No matching records found"
                v-if="isShow"
                >
                <vxe-table-column field="merchantCode" :title="$t('Merchant code')" ></vxe-table-column>
                <vxe-table-column field="userName" :title="$t('Merchant name')"  >
                    <template #default="{ row }">
                        <validation-provider
                        #default="{ errors }"
                        name="userName"
                        rules="required"
                         v-if="!row.id"
                        >
                        <b-form-input v-model="row.userName"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <span v-else>{{row.userName}}</span>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="createAt" v-if="isEdit" :title="$t('Creation Time')"  >
                </vxe-table-column>
                <vxe-table-column field="email" :title="$t('Email')"
                >
                    <template #default="{ row }">
                    <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                    >
                        <b-form-input
                        v-model="row.email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    field="userAccount" :title="$t('Enable user account')">
                    <template #default="{ row }">
                    <b-form-checkbox
                        v-model="row.userAccount"
                        :class="row.userAccount ? 'custom-control-info': 'custom-control-secondary'"
                    >
                    </b-form-checkbox>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="isEnabled" :title="$t('Active')" >
                    <template #default="{ row }">
                    <b-form-checkbox
                        v-model="row.isEnabled"
                        :class="row.isEnabled ? 'custom-control-info': 'custom-control-secondary'"
                    >
                    </b-form-checkbox>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="$t('Action')" width="100" show-overflow>
                <template #default="{ row }" >
                    <!-- <vxe-button type="text" icon="" @click="removeEvent(row)">
                    <feather-icon icon="Trash2Icon" />
                    </vxe-button> -->
                    <vxe-button type="text" icon="" @click="saveEvent(row)">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i>
                    </vxe-button>
                </template>
                </vxe-table-column>
                </vxe-table>
                <!-- show pages -->
                <div class="d-flex justify-content-between flex-wrap ll-p mt-1" v-if="isEdit">
                    <div class="d-flex align-items-center mb-0 mt-1 mr-1">
                        <span class="text-nowrap ">
                        Showing 1 to
                        </span>
                        <b-form-select
                        v-model="size"
                        :options="pageOptions"
                        class="mx-1"
                        @input="handlePageChange"
                        />
                        <span class="text-nowrap"> of {{ totalSize }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                        v-model="page"
                        :total-rows="totalSize"
                        :per-page="size"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @change="handleChangePage"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </section>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    props: {
        id: {
            type: Number
        },
        isEdit: {
            type: Boolean,
            default: false
        },
    },
    components:{ToastificationContent,},
    data(){
        return{
            formData: { name: '' },
            items: [{ userName: null, email: null, userAccount: false, isEnabled: false }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            pageOptions: ["10", "50", "100"],
            page: null,
            size: "10",
            totalSize: null,
            totalPage: null,
            condition: {
                page: 1,
                size: "10",
            },
            validRules: {},
            isShow:false,
            info:{},
        }
    },
    mounted(){
        if(this.id != 0 && this.id){
            this.getDetails()
            this.isShow = true
        }
    },
    methods:{
         getDetails() {
             this.condition.id = this.id
            this.$http.post('admin/settings/organization/getInfoById', this.condition).then(res => {
                // console.log(res.data.data)
                const info = res.data.data
                this.info = info
                if (info.merchantList.content.length !== 0) {
                this.items = info.merchantList.content
                } 
                this.totalSize = info.merchantList.totalSize
                this.totalPage = info.merchantList.totalPage
                this.$emit('orgCode', info.orgCode)
            })
        },
        // 插入新一行
        async insertEvent (row) {
            this.isShow = true
            const $table = this.$refs.xTable
            const record = {}
            const { row: newRow } = await $table.insertAt(record, row)
            await $table.setActiveCell(newRow)
        },
        // 查询merhant
        findList() {
            const len = this.items.length
            const arr = []
            for(var i=0;i<len;i++){
                if(this.items[i].userName.indexOf(this.formData.name)!== -1){
                arr.push(this.items[i])
                }
            }
            this.items = arr
        },
        saveEvent(row){
            if(row.userName === null ){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'userName can not be empty',
                        icon: 'XIcon',
                        variant: 'danger',
                    },
                })
                return
            }
            if(row.email === null ){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Email can not be empty',
                        icon: 'XIcon',
                        variant: 'danger',
                    },
                })
                return
            }
            let merchantList = [row]
            let orgCode = null
            if(this.info.orgCode){
                orgCode = this.info.orgCode
            }
            this.$http.post('/admin/settings/organization/saveMerchantList', {merchantList:merchantList,orgCode:orgCode}).then(res => {
               if(res.data.code == 200){
                    this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                setTimeout(() => {
                    this.getDetails()
                }, 1000);
            //    this.$router.push({ name: 'customer-list2' })
               }
            }).catch(error => console.log(error))
            
        },
        removeEvent (row) {
            this.box = ''
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete .', {
                title: 'Handle',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {
                if (value) {
                this.box = value
                console.info(row,'roe')
                if (row.id !== undefined){
                    this.$http.delete(`/admin/settings/organization/delete/${row.id}`).then(res=>{
                    if (res.data.code === '200') {
                        this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Delete Success',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                        })
                    } else {
                        this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                        })
                    }
                    setTimeout(() => {
                        this.getDetails()
                    }, 1000);
                    })
                }
                this.$refs.xTable.remove(row)
                }
            })
            },
        editClosedEvent ({ row, column }) {
            const $table = this.$refs.xTable
            const field = column.property
            const cellValue = row[field]
        },
        handleChangePage(page) {
            this.condition.page = page
            // this.getUserList()
        },
        handlePageChange(active) {
            this.condition.size = active
            // this.getUserList()
        },
    }
}
</script>
<style scoped>
</style>