<template>
  <section>
    <!-- base info -->
    <validation-observer ref="accountRules" v-slot="{ invalid }">
        <b-card no-body>
            <b-card-body class="ll-cbody">
                <b-row>
                    <b-col md="12">
                        <!-- user-avator -->
                        <div class="d-flex justify-content-start">
                        <b-avatar
                          :src="userData.coverImg"
                            :text="defaultName"
                            variant="light-info"
                            size="104px"
                            rounded
                        />
                        <div class="d-flex flex-column ml-1 ll-flex">
                            <div class="mb-1">
                            <h2 class="mb-0">
                                {{userData.userName}}
                            </h2>
                            </div>
                            <div class="d-flex flex-wrap">
                            <b-button
                                variant="info"
                                @click="$refs.refInputEl.click()"
                            >
                                <input
                                ref="refInputEl"
                                type="file"
                                class="d-none"
                                @input="inputImageRenderer($event)"
                                accept="image/jpeg, image/png, image/jpg"
                                >
                                <span class="d-none d-sm-inline">{{ $t('Change Avata') }}</span>
                                <feather-icon
                                icon="EditIcon"
                                class="d-inline d-sm-none"
                                />
                            </b-button>
                            <b-form-file
                                v-model="avata"
                                accept="image/*"
                                v-if="false"
                            />
                            </div>
                        </div>
                        </div>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('firstName')" rules="required">
                            <b-form-group :label="$t('First Name')" slot-scope="{ valid, errors }">
                                <b-form-input id="firstName" v-model="userData.firstName" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('lastname')" rules="required">
                            <b-form-group :label="$t('Last Name')" slot-scope="{ valid, errors }">
                                <b-form-input id="lastname" v-model="userData.lastName" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('company')" rules="required">
                            <b-form-group :label="$t('Company')" slot-scope="{ valid, errors }">
                                <b-form-input id="company" v-model="userData.orgName" :disabled="true" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('merchantCode')" rules="">
                            <b-form-group :label="$t('Merchant(seller)code')" slot-scope="{ valid, errors }">
                                <b-form-input id="merchantCode" v-model="userData.merchantCode"  :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('accountType')" rules="">
                            <b-form-group :label="$t('Account Type')" slot-scope="{ valid, errors }">
                               <div class="demo-inline-spacing">
                                    <b-form-radio
                                    v-model="userData.accountType"
                                    name="some-radios"
                                    value="SA"
                                    >
                                    API Account
                                    </b-form-radio>
                                    <b-form-radio
                                    v-model="userData.accountType"
                                    name="some-radios"
                                    value="UA"
                                    >
                                    User Account
                                    </b-form-radio>
                                </div>
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('role')" rules="required">
                            <b-form-group :label="$t('Role')" slot-scope="{ valid, errors }">
                                <v-select
                                    id="role"
                                    v-model="roles"
                                    :options="roleList"
                                    label="name"
                                    @input="getRole"
                                    multiple
                                    placeholder="Please select a role"
                                     :state="errors[0] ? false : valid ? true : null" 
                                ></v-select>
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('email')" rules="required|email">
                            <b-form-group :label="$t('Email')" slot-scope="{ valid, errors }">
                                <b-form-input id="email" v-model="userData.email" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('phone')" rules="">
                            <b-form-group :label="$t('Phone')" slot-scope="{ valid, errors }">
                                <b-form-input id="phone" v-model="userData.phone" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('department')" rules="">
                            <b-form-group :label="$t('Department')" slot-scope="{ valid, errors }">
                                <b-form-input id="department" type="text" v-model="userData.departmentName" autocomplete="off" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <div class="ll-subBtn">
        <b-button
            variant="info"
            @click.prevent="validationForm"
            :disabled="invalid"
        >
            {{ $t('Update') }}
        </b-button>
        </div>
        
    </validation-observer>
    <validation-observer ref="passwordRules" v-slot="{ invalid }">
        <b-card no-body>
            <b-card-body class="ll-cbody">
                <b-row>
                    <b-col md="12">
                        <validation-provider :name="$t('new_password')" rules="required">
                            <b-form-group :label="$t('Enter a new password')" slot-scope="{ valid, errors }">
                                <b-input-group
                                    class="input-group-merge"
                                >
                                <b-form-input
                                    id="new_password"
                                    v-model="newPwd1"
                                    :type="passwordFieldType"
                                    class="form-control-merge"
                                    :state="errors[0] ? false : valid ? true : null"
                                    autocomplete="new-password"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                    />
                                </b-input-group-append>
                                </b-input-group>
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider :name="$t('new_Repassword')" rules="required">
                            <b-form-group :label="$t('Re-enter a new password')" slot-scope="{ valid, errors }">
                                <b-input-group
                                    class="input-group-merge"
                                >
                                <b-form-input
                                    id="new_Repassword"
                                    v-model="newPwd2"
                                    :type="passwordFieldType1"
                                    class="form-control-merge"
                                    :state="errors[0] ? false : valid ? true : null"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon1"
                                    @click="togglePasswordVisibility1"
                                    />
                                </b-input-group-append>
                                </b-input-group>
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <div class="ll-subBtn">
                    <b-button
                        variant="info"
                        @click.prevent="passwordValidationForm"
                        :disabled="invalid"
                    >
                        {{ $t('Update Password') }}
                    </b-button>
                    </div>
            </b-card-body>
        </b-card>
    </validation-observer>
  </section>
</template>
<script>
import vSelect from 'vue-select'
import { togglePasswordVisibility, togglePasswordVisibility1 } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props:{
        userId:{
            type:Number
        }
    },
    components:{vSelect,ToastificationContent},
    data(){
        return{
            newPwd1:'',
            newPwd2:'',
            userData: {
                // username: '',
                password: '',
                email: '',
                phoneAreaCode: '',
                phone: '',
                // fullName: '',
                fullNameLocale: '',
                departmentName: '',
                company: '',
                coverImg: '',
                // sex: null,
                isEnabled: true,
                isLocked: false,
                organizationId: null,
                roleIds: [],
                firstName: '',
                lastName: '',
                accountType: 'UA',
                merchantCode: '',
            },
            roles: '',
            roleList: [],
            org:'',
            refInputEl: null,
            defaultName: '',
            isCan:null,
            isUpdateUser:true,
        }
    },
    mixins: [togglePasswordVisibility, togglePasswordVisibility1],
    watch: {
        userId(val) {
            this.userId = val
            this.getUserDetail()
        },
    },
    computed: {
        passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
        },
        passwordToggleIcon1() {
        return this.passwordFieldType1 === 'password' ? 'EyeOffIcon' : 'EyeIcon'
        },
    },
    mounted(){
        console.info(this.userId,'0--')
        this.queryOrg()
        this.queryRoleList()
        this.ability()
        this.getUserDetail()
    },
    methods:{
        validationForm(){
            const obj = {}
            obj.userId = this.userId
            obj.email = this.userData.email
            obj.phoneAreaCode = this.userData.phoneAreaCode
            obj.phone = this.userData.phone
            obj.firstName = this.userData.firstName
            obj.lastName = this.userData.lastName
            obj.departmentName = this.userData.departmentName
            obj.coverImg = this.userData.coverImg
            obj.status = this.userData.status
            obj.isEnabled = this.userData.isEnabled
            obj.organizationId = this.userData.organizationId
            obj.roleIds  = this.userData.roleId
            obj.accountType = this.userData.accountType
            obj.merchantCode = this.userData.merchantCode
            obj.userName = ''
            this.$http.post('/user/updateInfo', obj).then(res => {
                if (res.data.code === '200') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'User profile updated',
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                const userData = JSON.parse(localStorage.getItem('userInfo'))
                if (this.$route.params.userId == userData.userId) {
                    this.$router.push({ name: 'auth-login' })
                }else{
                    this.$emit('isUpdateUser', this.isUpdateUser)
                }
                // this.$router.push({ name: 'user-list' })
                }
            })
        },
        passwordValidationForm(){
            if (this.newPwd1.length > 5) {
                if (this.newPwd2 === this.newPwd1) {
                this.resetPwd()
                } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'new password inconsistency',
                    icon: 'XIcon',
                    variant: 'danger',
                    },
                })
                }
            } else {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'The password must be no less than 6 characters',
                    icon: 'XIcon',
                    variant: 'danger',
                },
            })
          }
        },
        resetPwd() {
            const data = {}
            data.id = this.userId
            data.password = this.newPwd1
            this.$http.post('user/resetPassword', data).then(res =>{
                if (res.data.code === '200') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: res.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                this.$router.push({ name: 'user-list' })
                }
            })
        },
        ability() {
            const userData = JSON.parse(localStorage.getItem('userInfo'))
            const arr = []
            userData.roleDtos.map(i =>{
                arr.push(i.name)
            })
            const name = 'Super Admin'
            let flag= arr.findIndex(i => i === name)
            if (flag !== -1) {
                this.isCan = true
            } else {
                this.isCan = false
            }
        },
        // 查询组织
        queryOrg() {
            this.org = this.orgName
            this.userData.organizationId = this.orgId
            this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
                this.orgList = res.data.data
            })
        },
        selOrg(o) {
            this.userData.organizationId = o.id
        },
        // 查询角色
        queryRoleList() {
            this.$http.get('/admin/settings/role/roleList').then(res => {
                let arr = res.data.data
                const arr1 = arr.filter(obj=>obj.name !=='Super Admin')
                this.roleList = arr1
            })
        },
        getRole(r) {
            const arr = []
            r.map(item => {
                arr.push(item.roleId)
            })
            this.userData.roleId = arr
        },
        getUserDetail() {
            this.$http.get('/user/getUserInfoById', { params: { userId: this.userId } }).then(res => {
                console.log('sss', res.data.data)
                const info = res.data.data
                this.$nextTick(()=>{
                this.userData = info
                this.organizationId = info.organizationId
                if (this.userData.firstName !== null || this.userData.lastName !== null) {
                this.defaultName = this.userData.firstName.substr(0,1) + this.userData.lastName.substr(0,1)
                } else {
                this.defaultName = this.userData.userName.substr(0,1)
                }
                this.roles = info.roleList
                // if (info.accountType === 'UA') {
                // this.accountType = 'User Account'
                // } else if (info.accountType === 'SA') {
                // this.accountType = 'System Account'
                // } else {
                // this.accountType = info.accountType
                // }
                this.userData.roleId = info.roleList.map(val=>{
                    return val.roleId
                })
                })
            })
        },
        inputImageRenderer(e) {
            const file = e.target.files[0]
            const filesize = file.size
            if (filesize > 2101440) {
                // 图片大于2MB
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Images must not exceed 2MB',
                    icon: 'XIcon',
                    variant: 'danger',
                },
                })
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => {
                // 转base64
                const imgcode = e.target.result
                this.userData.coverImg=imgcode
            }
        },
    }
}
</script>
<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>