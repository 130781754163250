<template>
  <section>
    <AdvanceTable ref="advanceTable" url="/user/search" :id="id" :hideColumns="hideColumns" :height="height" :columns="columns" :pageOptions="pageOptions" :defaultPageSize="defaultPageSize" @edit="openModalForEdit">
      <template #title>
        {{ $t("User List") }}
      </template>
      <template #button>
        <b-button variant="info" class="mr-1" v-b-modal.add-user >
          <feather-icon icon="PlusSquareIcon" class="mr-50" />
          <span class="align-middle">{{ $t("Add User") }}</span>
        </b-button>
      </template>
<!-- 
      <template #cell(is_merchant)="row">
        <b-badge variant="info" v-if="row.item.info.merchant_code" style="margin: 0px 2px; padding: 5px">Merchant</b-badge>
      </template> -->
      <template #cell(accountType)="row">
        <b-badge variant="info" v-if="row.value == 'UA'" style="margin: 0px 2px; padding: 5px">User Account</b-badge>
        <b-badge variant="dark" v-else style="margin: 0px 2px; padding: 5px">API Account</b-badge>
      </template>
      <template #cell(role)="row">
        <div v-if="row.value && row.value.length > 0">
          <b-badge variant="primary" v-for="(item,index) in row.value.split(',')" :key="index" style="margin: 0px 2px; padding: 5px" >{{ item }}</b-badge>
        </div>
      </template>
      <!-- <template #cell(info.is_locked)="row">
        <b-form-checkbox :checked="row.value" class="custom-control-danger" name="check-button" @change="updateStatus(row.item, 'is_locked')" switch>
          <span class="switch-icon-left">
            <feather-icon icon="LockIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="UnlockIcon" />
          </span>
        </b-form-checkbox>
      </template> -->
      <template #cell(isEnabled)="row">
        <b-form-checkbox :checked="row.value" class="custom-control-success" name="check-button" @change="updateStatus(row.item, 'is_active')" switch>
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </template>
    </AdvanceTable>

    <!-- Add User -->
    <b-modal
      id="add-user"
      centered
      :title="$t('Create User')"
      size="lg"
      v-model="isAdd"
      hide-footer
    >
      <create-user :orgName="name" :orgId="id" @isCreateUser="isCreateUser"></create-user>
    </b-modal>
    <!-- Edit User -->
    <b-modal
      id="edit-user"
      ref="edit-user"
      centered
      title=""
      size="lg"
      v-model="isEdit"
      hide-footer
    >
      <user-detail :userId="userId" @isUpdateUser="isUpdateUser"></user-detail>
    </b-modal>
  </section>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import createUser from './createUser2.vue'
import userDetail from './userDetail2.vue'
export default {
  components: {
    ToastificationContent,
    createUser,
    userDetail,
    AdvanceTable
  },
  props: {
    orgInfo: {
      default: null,
      type: Object
    },
    height: {
      default: 305,
      type: Number
    },
    hideColumns: {
      default: [],
      type: Array
    },
    pageOptions: {
      default: () => [5, 10, 50],
      type: Array
    },
    defaultPageSize: {
      default: 5,
      type: Number
    },
    id:{
        type:Number
    },
    name:{
      type:String
    }
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      selectedItem: null,
      columns: [
        { key: "username", modelName: "username", label: "Username", width: 160, filtertype: "input", sortable: true },
        { key: "email", modelName: "email", label: "Email", width: 250, filtertype: "input", sortable: true },
        { key: "accountType", modelName: "accountType", label: "Account Type", width: 120, align: "center", filtertype: "select", options: { UA: "User Account", SA: "API Account" } },
        { key: "organizationName", modelName: "organizationName", label: "Company", filtertype: "input",sortable: true},
        { key: "role", modelName: "role", label: "Roles", filtertype: "select", options: this.roleOptions },
        { key: "createdAt", modelName: "createdAt", label: "Creation Time", width: 180, filtertype: "datetime", sortable: true },
        { key: "isEnabled", modelName: "isEnabled", label: "Status", width: 90, align: "center", }
      ],
      isAdd: false,
      isEdit: false,
      userId:null,
      info:{}
    };
  },
  mounted() {
  },
  activated(){
  },
  methods: {
    openModalForEdit(item) {
      console.info(item,'1--')
      this.isEdit = true;
      this.showModal = true;
      this.selectedItem = item;
      this.userId = item.userId
    },
    isCreateUser(val) {
      if (val) {
        this.isAdd = false
        this.$refs.advanceTable.loadList();
      }
    },
    isUpdateUser(val){
      if (val) {
        this.isEdit = false
        this.$refs.advanceTable.loadList();
      }
    },
    updateStatus(item, type) {}
  }
};
</script>

<style scoped></style>
