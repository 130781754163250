var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"rules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t("Business Profile")))]),_c('div',{staticStyle:{"display":"flex"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},on:{"input":function($event){return _vm.updateStatus(_vm.form_data.requiredDim)}},model:{value:(_vm.form_data.requiredDim),callback:function ($$v) {_vm.$set(_vm.form_data, "requiredDim", $$v)},expression:"form_data.requiredDim"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]),_vm._v(" Weighting Required ")],1)],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Label Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Label Name')}},[_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isEdit,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Company Name')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.displayName),callback:function ($$v) {_vm.$set(_vm.form_data, "displayName", $$v)},expression:"form_data.displayName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Company Type')}},[_c('v-select',{attrs:{"options":_vm.typeList,"label":"codeDescription","disabled":_vm.isEdit,"state":errors[0] ? false : valid ? true : null},on:{"input":_vm.selType},model:{value:(_vm.form_data.orgType),callback:function ($$v) {_vm.$set(_vm.form_data, "orgType", $$v)},expression:"form_data.orgType"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('KE FOX code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('KE FOX code')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.keAccountNumber),callback:function ($$v) {_vm.$set(_vm.form_data, "keAccountNumber", $$v)},expression:"form_data.keAccountNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{attrs:{"id":"email","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.orgEmail),callback:function ($$v) {_vm.$set(_vm.form_data, "orgEmail", $$v)},expression:"form_data.orgEmail"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Address')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.address),callback:function ($$v) {_vm.$set(_vm.form_data, "address", $$v)},expression:"form_data.address"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Country')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.country),callback:function ($$v) {_vm.$set(_vm.form_data, "country", $$v)},expression:"form_data.country"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country Code'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Country Code')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.countryCode),callback:function ($$v) {_vm.$set(_vm.form_data, "countryCode", $$v)},expression:"form_data.countryCode"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pick Up Address'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Pick Up Address')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.pickUpAddress),callback:function ($$v) {_vm.$set(_vm.form_data, "pickUpAddress", $$v)},expression:"form_data.pickUpAddress"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pick Up District'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Pick Up District'),"label-for":"Pick Up district"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.pickUpDistrict),callback:function ($$v) {_vm.$set(_vm.form_data, "pickUpDistrict", $$v)},expression:"form_data.pickUpDistrict"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pick Up Area'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Pick Up Area'),"label-for":"Pick Up area"}},[_c('v-select',{attrs:{"options":_vm.areaList,"label":"codeDescription","state":errors[0] ? false : valid ? true : null},on:{"input":_vm.selArea},model:{value:(_vm.pickUpArea),callback:function ($$v) {_vm.pickUpArea=$$v},expression:"pickUpArea"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Phone')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.orgPhone),callback:function ($$v) {_vm.$set(_vm.form_data, "orgPhone", $$v)},expression:"form_data.orgPhone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Sales Name'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Sales Name')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.salesName),callback:function ($$v) {_vm.$set(_vm.form_data, "salesName", $$v)},expression:"form_data.salesName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Consignee Info in CUS Order Inbound Report')}},[_c('b-form-checkbox',{model:{value:(_vm.form_data.isShowConsigneeInfoInCusReport),callback:function ($$v) {_vm.$set(_vm.form_data, "isShowConsigneeInfoInCusReport", $$v)},expression:"form_data.isShowConsigneeInfoInCusReport"}},[_vm._v(" Enable ")])],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Auto-fill Pickup Schedule')}},[_c('b-form-checkbox',{model:{value:(_vm.form_data.autoFillPickup),callback:function ($$v) {_vm.$set(_vm.form_data, "autoFillPickup", $$v)},expression:"form_data.autoFillPickup"}},[_vm._v(" Yes (i.e. Next Business Day, Anytime) ")])],1)],1),_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Status Webhook URL'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Status Webhook URL')}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.statusUrl),callback:function ($$v) {_vm.$set(_vm.form_data, "statusUrl", $$v)},expression:"form_data.statusUrl"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Self Pick Vendor'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Self Pick Vendor')}},[_c('v-select',{staticClass:"sp-st",attrs:{"options":_vm.statusUrlList,"label":"agent","disabled":true,"state":errors[0] ? false : valid ? true : null,"multiple":""},model:{value:(_vm.form_data.spVendor),callback:function ($$v) {_vm.$set(_vm.form_data, "spVendor", $$v)},expression:"form_data.spVendor"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group","name":"selectedUrl"},on:{"input":function($event){return _vm.clearUrl(_vm.info.statusUrl)}},model:{value:(_vm.info.statusUrl),callback:function ($$v) {_vm.$set(_vm.info, "statusUrl", $$v)},expression:"info.statusUrl"}},_vm._l((_vm.statusUrlList),function(item){return _c('b-form-checkbox',{key:item.id,attrs:{"value":item.url,"checked":_vm.info.statusUrl}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group2","name":"selectedSP"},on:{"input":function($event){return _vm.spChange(_vm.info.spVendor)}},model:{value:(_vm.info.spVendor),callback:function ($$v) {_vm.$set(_vm.info, "spVendor", $$v)},expression:"info.spVendor"}},_vm._l((_vm.spList),function(item,index){return _c('b-form-checkbox',{key:index,attrs:{"value":item.agent,"switch":""}},[_c('span',{staticStyle:{"line-height":"25px"}},[_vm._v(_vm._s(item.agent))])])}),1)],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"info","disabled":invalid},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.isEdit ? _vm.$t("Update") : _vm.$t("Create"))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }