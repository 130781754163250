<template>
  <section>
    <div class="ll-topbox">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Company') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'customer-list2' }" >
            {{ $t('Company list') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{  orgCode ? orgCode :'Create Company' }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- back button -->
      <b-button
        variant="flat-secondary"
        class="ll-cancel"
        :to="{ name: 'customer-list2' }"
      >
        <b-img
          :src="require('@/assets/images/imgs/return.png')"
          height="auto"
          class="mr-1"
        />
      </b-button>
    </div>
    <div class="overflow-auto" :style="{ height: window.height - 178 + 'px' }">
      <businessProfile :id="orgId" :isEdit="orgCode ? true : false" @update="profileUpdate"  @create="profileCreate"/>
      <userList  :id="orgId" :name="orgName" v-if="orgId != 0" :hideColumns="['organization_name']" />
      <merchantList :id="orgId" v-if="orgId != 0" :isEdit="orgCode ? true : false" />
    </div>
  </section>
</template>
<script>
import {
  BBreadcrumb, BBreadcrumbItem, BImg, BTab, BTabs, BCard, BCardBody, BButton,
} from 'bootstrap-vue'
import businessProfile from './businessProfile.vue'
import userList from './userList.vue'
import merchantList from './merchantList.vue'
export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BTab,
    BTabs,
    BCard,
    BCardBody,
    BButton,
    businessProfile,
    userList,
    merchantList
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      orgCode:null,
      orgId:null,
      orgInfo:{},
      orgName:''
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created(){
      window.addEventListener("resize", this.handleResize);
    this.handleResize();
      this.orgId = this.$route.params.id
      if(Number(this.orgId) !=0){
        this.getOrgCode()
      }
      this.orgCode = this.$route.params.orgCode
      this.orgName = this.$route.params.name
  },
  methods:{
    getOrgCode() {
      this.$http.post('admin/settings/organization/getInfoById', {id:this.orgId}).then(res => {
        const item = res.data.data
        this.orgInfo = item
        this.orgCode = item.orgCode
        this.orgName = item.name
      })
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    profileCreate(item) {
      console.info('0--in')
      // this.orgInfo = item;
      this.orgId = item.id;
    },
    profileUpdate(item) {
      // this.orgInfo = item;
    },
  }
}
</script>

<style scoped>

</style>